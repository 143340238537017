import Link from "next/link";
import { localUrlEval } from "./localUrlEval";
import { textProcess } from "./textprocess";

export function linkify(input, user_name = "", website) {
  var inputString = input;

  var inputString = textProcess(inputString);

  //@github style username replacement
  inputString = linkifyUsername(inputString);
  // const regex_name = /@([a-zA-Z0-9-_]+)/g;
  // inputString = inputString.replace(regex_name, (match, group1) => {
  //     return `<a href="/${group1?.toLowerCase()}" target="_blank">${match}</a>`;
  // });

  //@github style username replacement
  const regex_id = /#([0-9]+)/g;
  inputString = inputString.replace(regex_id, (match, group1) => {
    return `<a href="/${user_name}/chat/${group1?.toLowerCase()}" title="${user_name}" target="_blank">${match}</a>`;
  });

  //link replacement
  const regex_link =
    /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?$;&//=]*)/g;
  inputString = inputString.replace(regex_link, (match) => {
    return `<a href="${localUrlEval(
      match,
      website
    )}" target="_blank" class="text-white underline hover:no-underline break-all" rel="noopener nofollow">${match}</a>`;
  });

  //bold replacement
  const regex_bold = /\*\*(.*?)\*\*/gm;
  inputString = inputString.replace(regex_bold, (match, group1) => {
    return `<b class="font-bold">${group1}</b>`;
  });

  //italics replacement*
  const regex_italic = /\*(.*?)\*/gm;
  inputString = inputString.replace(regex_italic, (match, group1) => {
    return `<i class="italic">${group1}</i>`;
  });

  //new line replacement
  const regex_break = /(\r\n|\r|\n)/gm;
  inputString = inputString.replace(regex_break, (match, group1) => {
    return `<br />`;
  });

  //timestamp replacement
  // const regex_timestamp =  /^(?:(?:([01]?\d|2[0-3]):([0-5]?\d):([0-5]?\d)$/gm;
  // inputString = inputString.replace(regex_timestamp, (match, group1) => {
  //     return `<br />`;
  // });

  return inputString;
}

export function linkifyUsername(n) {
  //@github style username replacement
  var inputString = n;
  const regex_name = /@([a-zA-Z0-9-_]+)/g;
  inputString = inputString.replace(regex_name, (match, group1) => {
    return `<a href="/${group1?.toLowerCase()}" target="_blank">${match}</a>`;
  });

  return inputString;
}

export const linkifyUsernameId = (
  name,
  id,
  className = " text-blue-500 hover:text-blue-400"
) => {
  if (id == 0) {
    return <span className={`text-red-500`}>{name}</span>;
  }
  return (
    <>
      <Link
        href={`/${name}`}
        className={`underline hover:no-underline ${className}`}
      >
        @{name}
      </Link>
    </>
  );
};
