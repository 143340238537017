import parsehtml from "@/lib/utils/parsehtml";
import parseMarkDown from "@/lib/utils/parseMarkDown";

export function markDown(n, o) {
  return parsehtml(parseMarkDown(n, o));
}

export function markDownReact(n, o) {
  return (
    <div className="markdown w-full">{parsehtml(parseMarkDown(n, o))}</div>
  );
}
